<template>
  <div class="container">
    <el-form :model="searchForm" ref="searchForm" label-width="70px" class="searchForm">

      <el-row>
        <el-col :span='6'>
          <el-form-item label="项目名称">
            <el-select v-model="searchForm.proName" @handleChange="handleSelect($event,)" filterable
              default-first-option :placeholder="'请选择'" clearable class="default">
              <el-option v-for="item in projectName" :key="item.id" :value="item.label">
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6;">ID:{{ item.id }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="分类名称">
            <selects @handleChange="handleSelect($event,1)" :isAllow='true' ref="cateNameRef"
              :options="cateName">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="缺陷名称">
            <el-input type="text" v-model="searchForm.defName" class="default"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="负责人">
            <el-input type="text" v-model="searchForm.proManager" class="default"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span='6'>
          <el-form-item label="版本号">
            <el-input type="text" v-model="searchForm.proVersion" class="default"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="优先级">
            <selects @handleChange="handleSelect($event,2)" ref="prioriRef" :options="defPriority">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-form-item label="状态">
            <selects @handleChange="handleSelect($event,3)" ref="statusRef" :options="status">
            </selects>
          </el-form-item>
        </el-col>
        <el-col :span='6'>
          <el-button class="formBtn" @click="openNewDefect(id=-1)">新建缺陷</el-button>
          <el-button class="formBtn btn-reset" @click="resetForm">重置</el-button>
          <el-button class="formBtn" @click="search(type=-1)" :loading="Searching">
            {{Searching ?  '搜索中...':'搜索'}}</el-button>
        </el-col>
      </el-row>

    </el-form>

    <createDefect @createDefect="handleNewDef" ref="createDefect"></createDefect>

    <!-- 项目列表 -->
    <div>
      <el-table :data="defectList" stripe v-loading="Searching" :row-style="{height:'64px',}"
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'border-top':'solid 1px #E3E3E3','background': '#F5F5F5','text-align':'center'}"
        style="width: 100%">
        <el-table-column prop="id" label="缺陷编号" width="100" align="center">
        </el-table-column>
        <el-table-column prop="defCreateTime" label="创建时间" min-width="130">
        </el-table-column>
        <el-table-column prop="defEndTime" label="结束时间" min-width="130">
        </el-table-column>
        <el-table-column prop="defName" label="缺陷名称" min-width="180">
          <template slot-scope="scope">
            <el-tooltip :content="scope.row.defIntroduce ?  scope.row.defIntroduce : '暂无缺陷描述'">
              <el-link type="primary" :href='scope.row.pageUrl' icon="el-icon-link">
                {{scope.row.defName}}</el-link>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="120">
          <template slot-scope="scope">
            <el-dropdown trigger="click" @command="handleCommand($event,scope.row.id)">
              <el-button type="text" class="el-dropdown-link" :disabled='scope.row.status==2'>
                {{(scope.row.status==0) ? '打开' : ''}}
                {{(scope.row.status==1) ? '关闭' : ''}}
                {{(scope.row.status==2) ? '已解决' : ''}}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="0" :disabled="scope.row.status==0">打开</el-dropdown-item>
                <el-dropdown-item command="1" :disabled="scope.row.status==1">关闭</el-dropdown-item>
                <el-dropdown-item command="2">已解决</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
        <el-table-column prop="proName" label="项目名称" min-width="180">
        </el-table-column>
        <el-table-column prop="proVersion" label="版本号" min-width="120">
        </el-table-column>
        <el-table-column prop="cateName" label="需求名称" min-width="120">
        </el-table-column>
        <el-table-column prop="defSolver" label="锅大侠" min-width="120">
        </el-table-column>
        <el-table-column prop="proManager" label="负责人" min-width="120">
          <template slot-scope="scope">
            <p class="longInfo" style="color: #409eff;">{{scope.row.proManager}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="优先级" min-width="80">
          <template slot-scope="scope">
            <span>
              {{(scope.row.defPriority==0) ? '高' : ''}}
              {{(scope.row.defPriority==1) ? '中' : ''}}
              {{(scope.row.defPriority==2) ? '低' : ''}}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" min-width="160">
          <template slot-scope="scope">
            <el-button type="text" @click="openNewDefect(scope.row.id)">编辑</el-button>
            <el-button type="text" @click="openNewDefect(scope.row.id,copy=1)">复制</el-button>
            <el-button type="text" @click="deleteApi(scope.row.id)" class="delectBtn">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 分页器 -->
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page="1" :page-sizes="[5, 10, 15]" :page-size="100"
        layout="total, sizes, prev, pager, next, jumper" :total="dataTotal">
      </el-pagination>
    </div>

  </div>
</template>

<script>
import {
  POST_ADDEDITDEF,
  POST_QUERYDEFECT,
  GET_DELEDEFECT,
} from "../request/api";
import { selects, createDefect } from "./parts";
export default {
  data() {
    return {
      dataTotal: 0,
      Searching: false, //搜索中
      searchForm: {
        proName: "", //项目名称
        cateName: "", //接口名称
        defName: "", //缺陷名称
        proManager: "", //项目经理
        proVersion: "", //版本号
        defPriority: "", //优先级
        status: "", //状态

        pageNumber: 1,
        pageSize: 5,
      },
      // 项目名称
      projectName: [],
      //   分类名称
      cateName: [
        { value: "测试接口", label: "测试接口" },
        { value: "正式接口", label: "正式接口" },
        { value: "开发接口", label: "开发接口" },
      ],
      //优先级
      defPriority: [
        { value: "0", label: "高" },
        { value: "1", label: "中" },
        { value: "2", label: "低" },
      ],
      //状态
      status: [
        { value: "0", label: "打开" },
        { value: "1", label: "关闭" },
        { value: "2", label: "已解决" },
      ],

      defectList: [],
    };
  },
  created() {
    this.searchForm.proName = this.$store.state.currentProject.label;
    this.projectName = this.$store.state.projectName;
    this.search();
  },
  methods: {
    // 重置搜索条件
    resetForm() {
      this.searchForm.proName = "";
      this.searchForm.cateName = "";
      this.searchForm.defName = "";
      this.searchForm.proManager = "";
      this.searchForm.proVersion = "";
      this.searchForm.defPriority = "";
      this.searchForm.status = "";
      let refList = ["cateNameRef", "statusRef", "prioriRef"];
      refList.forEach((element) => {
        this.$refs[element].resetValue();
      });
      this.search();
    },
    // 下拉框选择赋值
    handleSelect(val, type) {
      if (type == 0) {
        this.searchForm.proName = val;
      } else if (type == 1) {
        this.searchForm.cateName = val;
      } else if (type == 2) {
        this.searchForm.defPriority = val;
      } else {
        this.searchForm.status = val;
      }
    },
    // 打开新建项目对话框
    openNewDefect(id, copy) {
      this.$refs.createDefect.openNewDefect(id, copy);
    },
    // 新建缺陷
    handleNewDef(val) {
      POST_ADDEDITDEF(val)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({ title: "操作成功", type: "success" });
          } else {
            this.$notify.error({
              title: "操作失败",
            });
          }
          setTimeout(() => {
            this.search();
            this.$refs.createDefect.handleClose();
          }, 400);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 查询搜索
    search(type) {
      if (type == -1) {
        this.searchForm.pageNumber = 1;
      }
      this.Searching = true;
      const waitsearch = setTimeout(() => {
        this.Searching = false;
      }, 600);
      POST_QUERYDEFECT(this.searchForm)
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.dataTotal = res.data.total;
            this.defectList = res.data.list;
          }
          waitsearch;
        })
        .catch((err) => {
          console.log(err);
          waitsearch;
        });
    },
    //删除项目
    deleteApi(id) {
      this.$confirm("此操作将永久删除该缺陷, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          GET_DELEDEFECT({ id })
            .then(({ data: res }) => {
              if (res.resCode == 1) {
                this.$notify.success({
                  title: "成功",
                  message: "删除成功",
                });
                this.search();
              } else {
                this.$notify.error({
                  title: "失败",
                  message: res.resMsg,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    // 下拉修改状态
    handleCommand(command, id) {
      POST_ADDEDITDEF({
        id: id,
        status: command,
      })
        .then(({ data: res }) => {
          if (res.resCode == 1) {
            this.$notify({ title: "状态修改成功", type: "success" });
          }
          this.search();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 分页器
    handleSizeChange(val) {
      this.searchForm.pageSize = val;
      this.search();
    },
    handleCurrentChange(val) {
      this.searchForm.pageNumber = val;
      this.search();
    },
  },
  computed: {
    name() {
      return this.$store.state.currentProject;
    },
  },
  watch: {
    name(newValue) {
      this.searchForm.proName = newValue.label;
    },
  },
  components: {
    selects,
    createDefect,
  },
};
</script>

<style lang="less" scoped>
.default {
  width: 200px;

  /deep/ .el-input__inner {
    height: 36px;
    line-height: 36px;
    border-radius: 0;
  }
}
.searchForm {
  margin-bottom: 25px;
}

// 搜索按钮
.formBtn {
  height: 36px;
  background: #3285ea;
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  line-height: 36px;
  padding: 0;
  border-radius: 0;
  padding: 0 22px;
}
.btn-reset {
  color: rgba(0, 0, 0, 0.65);
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.delectBtn {
  margin-left: 16px;
}
// 外链描述等内容
.longInfo {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
